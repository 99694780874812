import React, { Component } from 'react';
import './scss/main.scss';
import Player from './components/Player';

class App extends Component {

  constructor(props) {
      super(props)
      this.state = {

      }
    
  }

  componentDidMount() {

    // #### add emoji at position of the user's cursor

    // document.getElementById('board').addEventListener('click', function(e){
    //   console.log(e.screenY-50);
    //   this.innerHTML += "<div><i class=\"em em-bird emoji-usr\" aria-role=\"presentation\" aria-label=\"BIRD\" style=\"left:"+ e.screenX+"px; top:"+e.screenY+"px\"></i></div>"
    // }) 

    // ####



  }

  render() {
    return (
    
      <div id="board">
        
        <link href="https://emoji-css.afeld.me/emoji.css" rel="stylesheet"></link>
  
        <div className="container">

          <div className="headline">
            <h1>zimmermukke</h1>
          </div>
          <div className="sub-headline">
            <h2>next rotation comming soon</h2>
          </div>

          <div className="player">
            <Player/>
          </div>

        </div>
  
      </div>
    );
  }

}

export default App;