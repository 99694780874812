import React, { Component } from 'react';
import Countdown from 'react-countdown';
import {
    isBrowser,
    isMobile,
    isAndroid,
    isIOS,
    BrowserView,
    MobileView
  } from "react-device-detect";
import $ from "jquery";

import '../scss/player.scss';
import '../js/Player.js';
import Iframe from 'react-iframe'

class Player extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showPlayer: false,
            showCountdown: false,
            source: 'http://3.22.67.8:8000/zimmermukke',
            date: '2020-07-04T20:00:00+02:00',
            loading: false,
            audioAvailable: false
        }
       
    }

    async checkIfAudioIsReady(url){
        const result = await fetch(url);
        if(result.status === 200){
            return true
        }else {
            return false
        }
    }

    addEventAudioListeners() {
        //disable cache of stream - stream reloads after pause is pressed
        var audio = document.getElementsByTagName('audio')

        if(audio.length > 0){

            console.log(audio)
            audio[0].addEventListener('pause', function(){
                audio[0].load()
            })
            
            audio[0].addEventListener('canplay', function(){
                this.setState({
                    showPlayer: true,
                    showCountdown:false
                })
                console.log('ON AIR')
    
            }.bind(this))
    
            audio[0].addEventListener('error', function(){
                this.setState({
                    showPlayer: false,
                    showCountdown: true
                })
            }.bind(this))

        }

    }
    
    componentWillMount() {
        
        // uncomment for checking available audio

        // if(this.checkIfAudioIsReady('http://3.22.67.8:8000/zimmermukke')){
        //     this.setState({
        //         audioAvailable: true,
        //         showPlayer: true, 
        //         showCountdown: false
        //     })
        // }else {
        //     this.setState({
        //         audioAvailable: false,
        //         showPlayer: false, 
        //         showCountdown: true
        //     })
        // }
    }

    componentDidMount() {
        if(this.state.audioAvailable){
            this.addEventAudioListeners()
        }
    }

    render() {
    
        return (
            <div className="container">

                {this.state.showCountdown &&
                    <div className="offline-status">
                        <div className="status"> 
                            <p>
                                <span className="upcoming-date">
                                    NEXT ROTATION:
                                </span>  
                            </p> 
                        
                        </div>
                        <div className="countdown"><Countdown date={this.state.date} /></div>
                    </div>
                }

                {this.state.showPlayer &&

                    <div className="tape">

                        <BrowserView>
                            <audio
                                controls
                                autoplay
                                src={this.state.source}
                                type="audio/mpeg">
                                    Your browser does not support
                                    <code>audio</code> element.)
                            </audio>
                        </BrowserView>

                        <MobileView>
                            {isIOS ?(
                                <audio
                                    controls
                                    autoplay
                                    src={this.state.source}
                                    type="audio/mpeg">
                                        Your browser does not support
                                        <code>audio</code> element.)
                                </audio>
                                ):(
                                <iframe src={this.state.source} allow="" id="iframeAudio"></iframe>
                            )}

                            {/* {isAndroid &&
                                <iframe src={this.state.source} allow="" id="iframeAudio"></iframe>
                            } */}
                        </MobileView>


                        
                    </div>
                }

            </div>
    
        )
    }


}

export default Player